import React, { useState } from 'react'

import './Schedule.css';

import DepartIcon from './assets/DepartIcon.png';

export default function Schedule() {
  const [day, setDay] = useState('sat');

  const satEvents = [
    { time: '00:00', location: 'LOREM XXX', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM XXX', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM XXX', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM XXX', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM XXX', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
  ];
  const sunEvents = [
    { time: '00:00', location: 'LOREM YYY', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM YYY', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM YYY', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM YYY', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
    { time: '00:00', location: 'LOREM YYY', description: 'Lorem ipsum dolor sit amet, consectetur adipi' },
  ];

  return (
    <div className='w-full min-h-screen' style={{ backgroundColor: '#E3EFF2' }} id="schedule">
      <div className='flex flex-col items-center'>
        <p className='text-4xl md:text-4xl lg:text-5xl xl:text-6xl m-5 head font-bold tracking-widest pt-5'>SCHEDULE</p>
        <div className='flex justify-center items-center mb-3 txt'>
          <button onClick={() => setDay('sat')} className={`w-40 text-2xl md:text-3xl lg:text-4xl xl:text-4xl rounded-l-xl ${day === 'sat' ? 'select' : 'bg-white nonselect'} px-3 py-3`}>Day 1</button>
          <button onClick={() => setDay('sun')} className={`w-40 text-2xl md:text-3xl lg:text-4xl xl:text-4xl rounded-r-xl ${day === 'sun' ? 'select' : 'bg-white nonselect'} px-3 py-3`}>Day 2</button>
        </div>
        <div className='w-5/6 xl:w-2/3 h-2/3 bg-white mb-10 rounded-2xl border text-xs md:text-base lg:text-lg xl:text-xl all'>
          <div className='flex justify-center items-center p-5'>
            <img alt='' src={DepartIcon} className='w-12 mr-2' />
            <p className='font-bold text-2xl'>Departures</p>
          </div>
          <div className='flex justify-left bg-gray-100 text-xs md:text-sm xl-text-sm'>
            <p className='w-1/6 p-3 border-2 text-center'>Time</p>
            <p className='w-1/5 p-3 border-2 text-center'>Location</p>
            <p className='flex-grow p-3 border-2 text-center'>Description</p>
          </div>
          {day === 'sat' ?
            satEvents.map(({ time, location, description }, index) => {
              return (
                <div key={index} className={`flex justify-left ${index === satEvents.length - 1 && 'rounded-b-2xl'} h-24 md:h-24 lg:h-20 xl:h-20`}>
                  <p className={`w-1/6 p-3 ${index === satEvents.length - 1 && 'rounded-bl-2xl'} border-2 break-all text-center`}>{time}</p>
                  <p className='w-1/5 p-3 border-2 break-all text-center'>{location}</p>
                  <p className={`flex-grow p-3 ${index === satEvents.length - 1 && 'rounded-br-2xl'} border-2 break-all text-center`} style={{flex: '1 0 50%'}}>{description}</p>
                </div>
              )
            })
            :
            sunEvents.map(({ time, location, description }, index) => {
              return (
                <div key={index} className={`flex justify-left ${index === satEvents.length - 1 && 'rounded-b-2xl'} h-24 md:h-24 lg:h-20 xl:h-20`}>
                  <p className={`w-1/6 p-3 ${index === satEvents.length - 1 && 'rounded-bl-2xl'} border-2 break-all text-center`}>{time}</p>
                  <p className='w-1/5 p-3 border-2 break-all text-center'>{location}</p>
                  <p className={`flex-grow p-3 ${index === satEvents.length - 1 && 'rounded-br-2xl'} border-2 break-all text-center`} style={{flex: '1 0 50%'}}>{description}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}